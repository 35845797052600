import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Home from "./pages/Home";
import Success from "./pages/Success";
import Preference from "./pages/Preference";
import axiosMiddleware from "./middleware/axios";

axiosMiddleware();

const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Preference />
        </Route>
        <Route path="/optout">
          <Home />
        </Route>
        <Route path="/success">
          <Success />
        </Route>
        <Route path="/subscription-management/:email">
          <Preference />
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
