import md5 from "md5";

import { decToHex } from "../helpers/numbers";
import { getWeekNumber } from "../helpers/dateTime";

const getAccessToken = () => {
  // Key
  const key = process?.env?.REACT_APP_apiKey || "location";
  const tokenSeed = process?.env?.REACT_APP_apiTokenSeed
    ? md5(process?.env.REACT_APP_apiTokenSeed)
    : "unauthorized";

  // Token
  const date = new Date();

  const year = date.getUTCFullYear();
  const week = getWeekNumber();
  const month = date.getUTCMonth() + 1;
  const day = date.getUTCDate();

  const tokenPrefix = decToHex(month * week * day);
  const tokenSuffix = decToHex((year - month - week) * year);
  const token = tokenPrefix + tokenSeed + tokenSuffix;

  return {
    key,
    token,
  };
};

export default getAccessToken;
