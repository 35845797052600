import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "styled-components";

import App from "./App";
import theme from "./ui/theme/shift";
import GlobalFonts from "./ui/theme/fonts";
import GlobalStyles from "./ui/theme/GlobalStyles";

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <React.StrictMode>
      <GlobalFonts />
      <GlobalStyles />
      <App />
    </React.StrictMode>
  </ThemeProvider>,
  document.getElementById("root")
);
