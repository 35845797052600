import styled, { css } from "styled-components";
import { theme, ifProp, switchProp, palette } from "styled-tools";

import LogoShift from "../assets/images/logo.png";
import HeaderBackground from "../assets/images/header.jpg";

export const HeaderStyled = styled.header`
  background: url(${HeaderBackground}) top center no-repeat;
  background-size: cover;
  height: 40vw;
  text-align: left;

  @media (min-width: ${theme("Breakpoints.md")}) {
    height: 26vw;
  }

  .logo {
    margin-top: ${theme("spacing.small")};
    max-width: 60px;

    @media (min-width: ${theme("Breakpoints.md")}) {
      margin-top: ${theme("spacing.medium")};
      max-width: 111px;
    }
  }

  h1 {
    margin-top: ${theme("spacing.large")};
    margin-bottom: ${theme("spacing.medium")};
    text-transform: uppercase;
    text-align: center;
    font-weight: 500;
    font-size: ${theme("typography.size.h1")};
    color: ${palette("white")};
  }
`;

export const Header = ({ children }) => (
  <HeaderStyled>
    <Container>
      <img src={LogoShift} className="logo" alt="Logo Shift Network" />
      {children}
    </Container>
  </HeaderStyled>
);

export const FooterStyled = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${theme("spacing.large")} 0;
  margin-top: ${theme("spacing.extralarge")};
  height: 100px;
  color: ${palette("white")};
  background-image: linear-gradient(
    -94deg,
    #399fd7 0%,
    #235790 99%,
    #235790 100%
  );

  img {
    margin-bottom: ${theme("spacing.small")};
  }
`;

export const Footer = () => (
  <FooterStyled>
    <img src={LogoShift} className="logo" alt="Logo Shift Network" />
    <div>©THE SHIFT NETWORK 2022</div>
  </FooterStyled>
);

export const Button = styled.button`
  display: inline-block;
  color: ${palette("white")};
  border-radius: 26px;
  text-transform: uppercase;
  line-height: inherit;
  font-weight: 700;
  padding: ${theme("spacing.buttonV")} ${theme("spacing.buttonH")};
  background-image: linear-gradient(
    89deg,
    #319ed9 0%,
    #319ed9 7%,
    #205893 99%,
    #205893 100%
  );
  min-width: 100px;
  border: 2px solid ${palette("white")};

  &:hover,
  &:active {
    border-color: ${palette("primary")};
    background: ${palette("white")};
    color: ${palette("primary")};
  }

  ${switchProp("variant", {
    outlined: css`
      border-color: ${palette("error")};
      background: ${palette("white")};
      color: ${palette("error")};
    `,
  })}
`;

const StyledContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  padding-left: ${theme("spacing.medium")};
  padding-right: ${theme("spacing.medium")};

  @media (min-width: ${theme("Breakpoints.sm")}) {
    max-width: ${theme("containerWidths.sm")};
  }

  @media (min-width: ${theme("Breakpoints.md")}) {
    max-width: ${theme("containerWidths.md")};
  }

  @media (min-width: ${theme("Breakpoints.lg")}) {
    max-width: ${theme("containerWidths.lg")};
  }

  @media (min-width: ${theme("Breakpoints.xl")}) {
    max-width: ${theme("containerWidths.xl")};
  }

  @media (min-width: ${theme("Breakpoints.xxl")}) {
    max-width: ${theme("containerWidths.xxl")};
  }
`;

export const Container = ({ children }) => (
  <StyledContainer>{children}</StyledContainer>
);

export const Page = styled.section`
  font-size: ${theme("typography.size.body")};
  line-height: ${theme("typography.size.heading")};
  text-align: ${ifProp({ align: "center" }, "center")};
  background: ${theme("white")};

  main {
    margin: 0 auto;
    max-width: 800px;
    padding-left: ${theme("spacing.medium")};
    padding-right: ${theme("spacing.medium")};
    min-height: 36vh;
  }

  .center {
    text-align: center;
  }

  .row {
    margin: 15px -15px;
  }

  .col {
    flex: 0 0 calc(50% - 30px);
    margin: 0 15px;

    &--full {
      margin: 30px auto;
      max-width: 500px;
      flex: 0 0 calc(100% - 30px);
    }
  }

  .mt-5 {
    margin-top: 50px;
  }

  h1 {
    margin-bottom: ${theme("spacing.medium")};
  }

  h2 {
    margin-bottom: ${theme("spacing.medium")};
  }

  p {
    flex: 0 0 100%;
    font-size: ${theme("typography.size.body")};
    line-height: ${theme("typography.size.heading")};
    margin-bottom: ${theme("spacing.medium")};
  }

  a {
    color: ${palette("primary")};
    text-decoration: underline;
  }

  .message {
    min-height: 30px;
    color: ${palette("error")};
  }

  form {
    margin: ${theme("spacing.medium")} 0;

    input {
      padding: 0 15px;
      width: 100%;
      height: 38px;
      border-radius: 5px;
      box-sizing: border-box;
      border: 2px solid ${palette("grayscale", 2)};
    }

    .legend {
      display: flex;
      flex: 0 0 100%;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid ${palette("grayscale", 2)};

      h2 {
        margin-bottom: 0;
        flex: 0 0 50%;
      }
    }

    fieldset {
      margin-bottom: ${theme("spacing.medium")};
      display: flex;
      flex-wrap: wrap;

      &.disabled {
        p,
        label {
          opacity: 0.5;
        }
      }

      label {
        flex: 0 0 calc(100% - 56px);
        font-size: ${theme("typography.size.heading")};
        margin-bottom: ${theme("spacing.medium")};
        font-weight: 700;
      }
    }
  }

  .actions {
    flex: 0 0 100%;
  }
`;
