import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import useRouter from "../hooks/useRouter";
import FormEmail from "../components/FormEmail";
import { Button, Page, Header, Footer } from "../components/StyledComponents";

const ERROR = "ERROR";
const SUBSCRIBED = "SUBSCRIBED";
const UNSUBSCRIBED = "UNSUBSCRIBED";

const Home = () => {
  const router = useRouter();
  const [isLoading, setIsLoading] = React.useState(false);
  const [status, setStatus] = React.useState();

  const { pns, email } = router.query;

  const theEmail = Array.isArray(email) ? email[0] : email;

  const unsubscribeUser = React.useCallback(async () => {
    try {
      if (pns && theEmail) {
        setIsLoading(true);

        await axios.post("/preference-center/event-subscription", {
          pns,
          email: theEmail,
          action: "unsubscribe",
        });

        setStatus(UNSUBSCRIBED);
      }
    } catch (error) {
      console.log(error);
      setStatus(ERROR);
    } finally {
      setIsLoading(false);
    }
  }, [pns, theEmail]);

  React.useEffect(() => {
    unsubscribeUser();
  }, [unsubscribeUser]);

  const resubscribeUser = React.useCallback(async () => {
    try {
      if (pns && theEmail) {
        setIsLoading(true);

        await axios.post("/preference-center/event-subscription", {
          pns,
          email: theEmail,
          action: "resubscribe",
        });

        setStatus(SUBSCRIBED);
      }
    } catch (error) {
      console.log(error);
      setStatus(ERROR);
    } finally {
      setIsLoading(false);
    }
  }, [pns, theEmail]);

  if (!theEmail) {
    return <FormEmail url="/optout" />;
  }

  return (
    <Page align="center">
      <Header>
        <h1>Preference center</h1>
      </Header>
      <main>
        {status === ERROR && (
          <>
            <h1>An error occurred</h1>

            <p>Try to refresh the page or try again later.</p>
          </>
        )}

        {status === UNSUBSCRIBED && (
          <>
            <h1>Thanks for letting us know!</h1>

            <p>You've successfully been unsubscribed from this event.</p>
            <p>Didn't mean to unsubscribe?</p>
          </>
        )}

        {status === UNSUBSCRIBED && (
          <Button disabled={isLoading} onClick={resubscribeUser}>
            {isLoading === true ? "Loading..." : "Resubscribe"}
          </Button>
        )}

        {status === UNSUBSCRIBED && (
          <Button variant="outlined" disabled>
            Unsubscribe
          </Button>
        )}

        {!!status && (
          <p className="mt-5">
            You may still receive emails from the Shift Network about other
            events. To manage you subscription, or to unsubscribe completely,{" "}
            <Link to={`/subscription-management/${theEmail}`}>Click Here</Link>
          </p>
        )}
      </main>

      <Footer />
    </Page>
  );
};

export default Home;
