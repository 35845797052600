/* eslint-disable import/no-anonymous-default-export */

const ENV = process.env.REACT_APP_environment || "development";

export default {
  apiUrl: process.env.REACT_APP_apiUrl,

  // ENV
  environment: ENV,
  isDev: process.env.NODE_ENV === "development",
};
