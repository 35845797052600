import { createGlobalStyle } from 'styled-components';
import PoppinsRegular from '../fonts/Poppins/Poppins-Regular.ttf';
import FuturaStdMedium from '../fonts/Futura/FuturaStdMedium.woff';

const GlobalFonts = createGlobalStyle`

  @font-face {
    font-family: 'PoppinsRegular';
    src: url(${PoppinsRegular}) format("truetype");
  }

  @font-face {
    font-family: 'Futura Std Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Futura Std Medium'), url(${FuturaStdMedium}) format('woff');
  }
`;

export default GlobalFonts;
