const baseFontSize = '16';

const getValueInREM = (multipleOfBaseline) => {
  return `${multipleOfBaseline / baseFontSize}rem`;
};

const palette = {
  primary: '#006fb5',
  secondary: '#68AADF',
  transparent: 'transparent',
  white: '#ffffff',
  black: '#000000',
  error: '#dc3545',
  grayscale: [
    '#D1D1D1',
    '#F2F2F2',
    '#cccccc',
    '#999999',
    '#767676',
    '#BABABA',
    '#F3F3F3',
    '#F8F8F8',
    '#E6E6E6',
    '#666666',
  ],
  header: '#999999',
  footer: '#ffffff',
  input: '#666666',
  buttonHover: '#69A1E0',
  buttonClicked: '#999999',
  pageBackground: '#FFFFFF',
};

const Breakpoints = {
  xs: '0',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1320px',
};

const containerWidths = {
  sm: '540px',
  md: '720px',
  lg: '960px',
  xl: '1140px',
  xxl: '1280px',
};

const spacing = {
  none: 0,
  small: getValueInREM(10),
  medium: getValueInREM(20),
  buttonH: getValueInREM(35),
  buttonV: getValueInREM(10),
  big: getValueInREM(44),
  large: getValueInREM(48),
  extralarge: getValueInREM(100),
  full: '100%',
};

const typography = {
  size: {
    small: getValueInREM(14),
    small1: getValueInREM(10),
    small2: getValueInREM(12),
    body: getValueInREM(16),
    p: getValueInREM(18),
    heading: getValueInREM(24),
    input: getValueInREM(26),
    price: getValueInREM(34),
    medium: getValueInREM(40),
    h1: getValueInREM(35),
    brandName: getValueInREM(100),
  },
  weight: {
    extralight: 200,
    light: 300,
    normal: 400,
    semi: 600,
    bold: 700,
  },
};

const Shift = {
  baseFontSize,
  spacing,
  typography,
  palette,
  Breakpoints,
  containerWidths,
};

export default Shift;
