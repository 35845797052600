import axios from "axios";

import appConfig from "../config";
import getAccessToken from "../config/apiAccessToken";

const axiosMiddleware = () => {
  /**
   * baseURL
   */
  axios.defaults.baseURL = appConfig.apiUrl;

  /**
   * timeout
   */
  axios.defaults.timeout = 30000;

  /**
   * request
   */
  axios.interceptors.request.use(
    (config) => {
      // NOTE: All requests need to be authenticated
      const { key, token } = getAccessToken();
      config.headers[key] = token;

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  /**
   * response
   */
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response?.status === 401) {
        // ...
      }

      return Promise.reject(error);
    }
  );
};

export default axiosMiddleware;
