import { useState } from 'react';
import PropTypes from 'prop-types';
import { Page, Button, Header, Footer } from './StyledComponents';

const FormEmail = ({ url }) => {
  const [emailUser, setEmailUser] = useState('');
  const submitEmail = () => {
    window.location.href = `${url}?email=${emailUser}`;
    return false;
  };
  return (
    <Page align="center">
      <Header>
        <h1>Preference center</h1>
      </Header>
      <main>
        <p>
          Please enter your email address to manage
          <br /> your subscription or to unsubscribe completely:
        </p>
        <form onSubmit={submitEmail}>
          <fieldset className="row">
            <div className="col col--full">
              <input
                required
                placeholder="Your email:"
                name="email"
                value={emailUser}
                onChange={(e) => setEmailUser(e.target.value)}
              />
            </div>
          </fieldset>
          <fieldset>
            <div className="actions center">
              <Button type="submit">Submit</Button>
            </div>
          </fieldset>
        </form>
      </main>
      <Footer />
    </Page>
  );
};

FormEmail.propTypes = {
  url: PropTypes.string.isRequired,
};

FormEmail.defaultProps = {
  url: '/opt-out',
};

export default FormEmail;
