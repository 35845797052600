import React from "react";
import Switch from "react-switch";
import { useParams } from "react-router-dom";
import axios from "axios";

import { useQuery } from "../helpers";
import { Page, Button, Header, Footer } from "../components/StyledComponents";
import FormEmail from "../components/FormEmail";

const FREE_CONTENT_ID = 39621;
const CATALYST_DIGEST_ID = 39530;
const PROGRAM_DELIVERY_ID = 55809;

const Preference = () => {
  let query = useQuery();
  const { email } = useParams();
  const [userData, setUserData] = React.useState({
    loading: false,
    dataFields: {
      $firstName: "",
      $lastName: "",
      email: "",
      birthDate: "",
      unsubscribedChannelIds: [],
    },
  });

  const [formData, setFormData] = React.useState(null);
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [switchCatalyst, setSwitchCatalyst] = React.useState(false);
  const [switchFreeContent, setSwitchFreeContent] = React.useState(false);
  const [switchProgramDelivery, setSwitchProgramDelivery] =
    React.useState(false);
  const [switchResponse, setSwitchResponse] = React.useState(null);

  let theEmail = email === undefined ? query.get("email") : email;

  const checkIfSubscribed = React.useCallback(
    (id) => {
      const unsubscribedChannelIds =
        userData?.dataFields?.unsubscribedChannelIds;
      if (!!unsubscribedChannelIds?.some((item) => item === id)) {
        return false;
      } else {
        return true;
      }
    },
    [userData]
  );

  React.useEffect(() => {
    if (theEmail) {
      axios
        .get(`/preference-center/user/${theEmail}`)
        .then((response) => {
          if (response.data.user) {
            setUserData(response.data.user);
          }
        })
        .catch((error) => console.log(error));
    }
  }, [theEmail]);

  React.useEffect(() => {
    setSwitchFreeContent(checkIfSubscribed(FREE_CONTENT_ID));
    setSwitchCatalyst(checkIfSubscribed(CATALYST_DIGEST_ID));
    setSwitchProgramDelivery(checkIfSubscribed(PROGRAM_DELIVERY_ID));
  }, [checkIfSubscribed]);

  const submitUpdate = (event) => {
    setFormData(null);
    setUserData({ ...userData, loading: true });

    const birthDate = userData?.dataFields?.birthDate
      ? `${userData.dataFields.birthDate} 00:00:00 +00:00`
      : undefined;

    axios
      .post(`/preference-center/user/${theEmail}`, {
        $firstName: firstName,
        $lastName: lastName,
        birthDate,
      })
      .then((response) => {
        setFormData(response.status);
        setUserData({ ...userData, loading: false });
      })
      .catch((error) => console.log(error));
    event.preventDefault();
  };

  const addSubscriptionChannel = async (channelID, email) =>
    axios
      .post("/preference-center/channel-subscription", {
        email,
        channelID,
        action: "subscribe",
      })
      .then((response) => response.data)
      .catch((error) => console.log(error));

  const deleteSubscriptionChannel = async (channelID, email) =>
    axios
      .post("/preference-center/channel-subscription", {
        email,
        channelID,
        action: "unsubscribe",
      })
      .then((response) => response.data)
      .catch((error) => console.log(error));

  const unSubscribeAll = async () => {
    setSwitchCatalyst(false);
    setSwitchFreeContent(false);
    setSwitchProgramDelivery(false);

    await deleteSubscriptionChannel(FREE_CONTENT_ID, theEmail);
    await deleteSubscriptionChannel(CATALYST_DIGEST_ID, theEmail);
    await deleteSubscriptionChannel(PROGRAM_DELIVERY_ID, theEmail);

    window.location.href = "/success";
  };

  const updateSubscription = (id, state, set) => {
    setSwitchResponse(null);
    set(!state);
    if (!state) {
      addSubscriptionChannel(id, theEmail);
    } else {
      deleteSubscriptionChannel(id, theEmail);
    }
    setSwitchResponse(id);
  };

  if (!theEmail) {
    return <FormEmail url="/subscription-management" />;
  }

  return (
    <Page>
      <Header>
        <h1>Notification Settings</h1>
      </Header>
      <main>
        <div className="center">
          <p>
            Share or update your information with us and we'll tailor the email
            updates we send you.
            <br />
            We promise to only use your info in accordance with our privacy
            policy.
          </p>
        </div>
        <div>
          <form onSubmit={submitUpdate}>
            <fieldset className="row">
              <div className="col">
                <input
                  placeholder="First Name"
                  name="firstName"
                  onChange={(e) => setFirstName(e.target.value)}
                  value={firstName}
                />
              </div>
              <div className="col">
                <input
                  placeholder="Last Name"
                  name="lastName"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
            </fieldset>
            <fieldset className="row">
              <div className="col">
                <strong className="message">
                  {formData === 202 || formData === 200
                    ? "Data was successfully saved"
                    : null}
                </strong>
              </div>
            </fieldset>
            <fieldset>
              <div className="actions center">
                <Button type="submit">
                  {userData.loading === true ? "Loading..." : "Update"}
                </Button>
              </div>
            </fieldset>
            <fieldset className="mt-5">
              <div className="legend">
                <h2>Settings</h2>
                <strong>Email</strong>
              </div>
            </fieldset>

            {/* Setting */}

            <fieldset className={!switchCatalyst ? "disabled" : null}>
              <label>Catalyst Digest</label>
              <Switch
                onChange={() =>
                  updateSubscription(
                    CATALYST_DIGEST_ID,
                    switchCatalyst,
                    setSwitchCatalyst
                  )
                }
                checked={switchCatalyst}
                uncheckedIcon={false}
                checkedIcon={false}
                onColor="#006fb5"
              />
              <p>
                Get our twice-a-month newsletter in your inbox for the latest on
                our training offerings, plus good news on inspiring shifts
                happening in consciousness and culture around the world.
              </p>
              <div className="center message">
                <strong>
                  {switchResponse === CATALYST_DIGEST_ID
                    ? "Data was successfully saved"
                    : null}
                </strong>
              </div>
            </fieldset>

            <fieldset className={!switchFreeContent ? "disabled" : null}>
              <label>Free Content & Invitations</label>
              <Switch
                onChange={() =>
                  updateSubscription(
                    FREE_CONTENT_ID,
                    switchFreeContent,
                    setSwitchFreeContent
                  )
                }
                checked={switchFreeContent}
                uncheckedIcon={false}
                checkedIcon={false}
                onColor="#006fb5"
              />
              <p>
                Receive the latest on new courses and transformational topics —
                plus invites to free online events like life-changing workshops,
                game-changing global summits, epic music festivals, and more.
              </p>
              <div className="center message">
                <strong>
                  {switchResponse === FREE_CONTENT_ID
                    ? "Data was successfully saved"
                    : null}
                </strong>
              </div>
            </fieldset>

            <fieldset className={!switchProgramDelivery ? "disabled" : null}>
              <label>Program Delivery</label>
              <Switch
                onChange={() =>
                  updateSubscription(
                    PROGRAM_DELIVERY_ID,
                    switchProgramDelivery,
                    setSwitchProgramDelivery
                  )
                }
                checked={switchProgramDelivery}
                uncheckedIcon={false}
                checkedIcon={false}
                onColor="#006fb5"
              />
              <p>
                Stay up to date on everything you need to know about your paid
                purchases, from course reminders and orientation details to
                homework and beyond.
              </p>
              <div className="center message">
                <strong>
                  {switchResponse === PROGRAM_DELIVERY_ID
                    ? "Data was successfully saved"
                    : null}
                </strong>
              </div>
            </fieldset>
          </form>
        </div>
        <div className="center mt-5">
          <p className="mt-5">Opt out of all communications.</p>
          <Button variant="outlined" onClick={() => unSubscribeAll()}>
            Unsubscribe from all emails
          </Button>
        </div>
      </main>
      <Footer />
    </Page>
  );
};

export default Preference;
