import { Page, Header, Footer } from "../components/StyledComponents";

const Success = () => {
  return (
    <Page align="center">
      <Header>
        <h1>Preference center</h1>
      </Header>
      <main>
        <h1>You have successfully unsubscribed.</h1>
        <p>
          Thank you for being part of the Shift Community. We’re here to help
          you awaken to your full potential and together create a beautiful
          world that works for all.
        </p>
      </main>
      <Footer />
    </Page>
  );
};

export default Success;
