import { useLocation } from 'react-router-dom';

export const removeItemOnce = (arr, value) => {
  let index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
};

export const addItemArray = (arr, value) => {
  if (arr.filter((item) => item === value).length === 0) arr.push(value);
  return arr;
};

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
